
import logo from './logo.svg';
import './App.css';
import { Navbar } from './Component/Navbar';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";
import { Footer } from './Component/Footer';

import { Home } from './Component/Home';

import { About } from './Component/About';

import { Contact } from './Component/Contact';

import {Gallery } from './Component/Gallery';
import { Audio } from 'react-loader-spinner'
;
function App() {
  return (
<>

    <Router>    
    <Navbar  title="Nameri Hotel"/>

    <Switch>

    <Route exact path="/" component={Home} />
      
    <Route exact path="/about" component={About} />
    <Route exact path="/gallery" >

<Gallery title="Sphinx Technology"/>
      </Route>
  

          <Route exact path="/contact" component={Contact} />
            
          
        </Switch>
<Footer/>



  



    </Router>
    </>

  );
}

export default App;
