import React from 'react'

export const About = () => {
  
  return (
    
    <div>

<p>
    Lorem Espam Testing
</p> 

    </div>
  )
}
