import React from 'react'

const style={


backgroundColor:'green',


}


;

const a=2;
const b=7;

const res=a+b;



export const Gallery = (props) => {
  return (
    <div>


<div className="jumbotron" style={style}>
  <h1 className="display-4 bg-warning text-white">
  
    Gallery is here {props.title} {res}
</h1>
  <p className="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
  <hr className="my-4"/>
  <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
  <p className="lead">
    <a className="btn btn-primary btn-lg" href="#" role="button">Learn more</a>
  </p>
</div>







<div className="container">
  <div className="row">
    <div className="col-sm">
    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg" className="img-fluid" alt="Responsive image"/>
  </div>
    <div className="col-sm">
    <img src="https://static.sadhguru.org/d/46272/1633199491-1633199490440.jpg" className="img-fluid" alt="Responsive image"/>

    </div>
    <div className="col-sm">
    <img src="https://d1whtlypfis84e.cloudfront.net/guides/wp-content/uploads/2019/07/23090714/nature-1024x682.jpeg" className="img-fluid" alt="Responsive image"/>

    </div>
  </div>
</div>




    </div>
  )
}
